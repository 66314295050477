<template>
  <molecule-card-wrapper class="room">
    <atom-heading type="h2" variant="sm" class="room__heading"
      >Vos communautés</atom-heading
    >
    <p class="room__desc">
      Espace d’échange, entre les communautés adhérentes, en matière d’actualité financière, le processus budgétaire et la loi de finances.
    </p>
    <p class="room__desc">
      Les communautés adhérentes /actives sont : 
      <strong>La coalition marocaine pour la redevabilité, les parlementaires, les journalistes, les étudiants chercheurs.</strong>
    </p>
    <p class="room__desc">
      Des échanges digitalisés respectueux aux règles scientifiques et à la politique d’utilisation de la plateforme.
    </p>
    <div class="room__communities">
      <molecule-community v-for="(community,index) in userCommunities" :community="community" :key="index" class="room__community" />
    </div>
  </molecule-card-wrapper>
</template>

<script setup>
import MoleculeCardWrapper from "./MoleculeCardWrapper.vue";
import MoleculeCommunity from "../MoleculeCommunity.vue";
import {toRefs, computed} from "vue";
const props = defineProps({
  communities:{
    type:Array,
    required:true
  }
});
const {communities} = toRefs(props);
const userCommunities = computed(()=>{
  return communities.value.filter(community=>community.name != 'public' );
});
</script>

<style lang="scss" scoped>
.room {
  @apply px-3;
  &__heading {
    @apply mb-1;
  }
  &__desc {
    @apply text-base text-black/80 mb-1;
    &:last-of-type{
      @apply mb-4;
    }
  }
  &__communities {
    @apply flex flex-col gap-2;
  }
}
</style>
