<template>
  <div class="forum__container">
    <main class="forum__body" ref="scrollComponent">
      <molecule-create-post-input
        class="forum__input"
      ></molecule-create-post-input>
      <block-forum-onboarding v-if="isFirstLogin"/>
      <block-posts :communityName="communityName" />
    </main>
    <aside class="forum__aside">
      <div class="forum__aside-container">
        <molecule-create-advocacy-card></molecule-create-advocacy-card>
        <molecule-communities-card
          v-show="userCommunities?.length"
          :communities="userCommunities"
        >
        </molecule-communities-card>
        <molecule-news-card v-if="news?.length" :news="news"></molecule-news-card>
      </div>
    </aside>
  </div>
</template>

<script setup>
import MoleculeCreateAdvocacyCard from "@/components/molecules/cards/MoleculeCreateAdvocacyCard.vue";
import MoleculeNewsCard from "@/components/molecules/cards/MoleculeNewsCard.vue";
import MoleculeCommunitiesCard from "@/components/molecules/cards/MoleculeRoomCard.vue";
import MoleculeCreatePostInput from "@/components/molecules/MoleculeCreatePostInput.vue";
import BlockPosts from "@/components/blocks/BlockPosts.vue";
import BlockConfirmationModal from "@/components/blocks/BlockConfirmationModal.vue";
import BlockForumOnboarding from "@/components/blocks/BlockForumOnboarding.vue";
import { useStore } from "vuex";
import { ref, computed, onMounted, onUnmounted, inject } from "vue";
import {
  communitiesMutationTypes,
  postsMutationTypes,
} from "@/store/types/mutations";
import { debounce } from "debounce";
const { state, getters, dispatch, commit } = useStore();
commit(
  `communities/${communitiesMutationTypes.SET_CURRENT_COMMUNITY_NAME}`,
  "public"
);

const news = computed(() => state.news.news?.data);

const fetchData = async () => {
  try {
    commit(`posts/${postsMutationTypes.SET_LOADING}`, true);
    await dispatch("posts/getByCommunityName");
    commit(`posts/${postsMutationTypes.SET_LOADING}`, false);
  } catch (err) {
    const { message } = err.response.data;
    console.log(message);
  }

  try {
    await dispatch("communities/getUserCommunities");
  } catch (err) {
    const { message } = err.response.data;
    console.log(message);
  }
};
const scrollComponent = ref(null);
const communityName = computed(() => state.communities.currentCommunityName);
const userCommunities = computed(() => state.communities.userCommunities);
const isFirstLogin = window.localStorage.getItem('isFirstLogin') == 'false' ? false:true;
const loadMorePosts = async () => {
  try {
    await dispatch("posts/getByCommunityName");
  } catch (err) {
    const { message } = err.response.data;
    console.log(message);
  }
};

const handleScroll = (e) => {
  let element = scrollComponent.value;
  if (element.getBoundingClientRect().bottom - 50 < window.innerHeight) {
    loadMorePosts();
  }
};

onMounted(async () => {
  window.addEventListener("scroll", debounce(handleScroll, 50));
  await fetchData();
  if(!news.value?.length){
    await dispatch('news/getAll', {
      page: 1,
      filter: 'filter.isAdvocacy=false'
    });
  }
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style lang="scss" scoped>
.forum {
  &__container {
    @apply container mx-auto px-4 mt-2 lg:mt-4
      flex lg:gap-[22px];
  }
  &__input {
    @apply mb-8;
  }
  &__body {
    @apply flex-[2];
  }
  &__aside {
    @apply hidden relative flex-1 
      lg:block;
    &-container {
      @apply flex flex-col relative max-w-lg gap-4;
    }
  }
}
</style>
