<template>
  <div class="community">
    <atom-image
      v-if="community.avatar"
      :src="imageUrl(community.avatar)"
      variant="rounded sm"
    ></atom-image>
    <div v-else class="h-8 w-8 rounded-full text-white text-lg leading-none font-semibold bg-primary flex justify-center items-center capitalise">
      {{community.name[0]}}
    </div>
    <atom-heading type="h3" variant="sm" class="community__heading">
      <span class="capitalize">
        {{community.name}}
      </span>
    </atom-heading>
      <atom-button class="community__button" variant="primary-outline" type="link" :to="{name:'ForumRoom', params:{communityName:community.name}}">
        Voir
      </atom-button>
    </div>
</template>

<script setup>
import { inject } from "vue";

const props = defineProps({
  community:{
    type:Object,
    required:true
  }
});
const imageUrl = inject('imageUrl');
</script>

<style lang="scss" scoped>
.community {
  @apply flex items-center gap-1;
  &__heading {
    @apply flex-1;
  }
  &__button {
    @apply border-2 text-base font-medium;
  }
}
</style>
