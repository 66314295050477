<template>
  <div v-if="isActive" class="onboarding">
    <atom-icon
        icon="close"
        class="onboarding__close"
        @click.prevent="hideOnboardingCard()"
      >
      </atom-icon>
    <img 
      :src="require('@/assets/icons/hello-icon.svg')" 
      alt=" Hello icon"
      class="onboarding__icon"  
    >
    <h2 class="onboarding__title">
      Bienvenu dans le forum E-tachawor
    </h2>
    <p class="onboarding__desc">
      Espace dédié aux contributions et interactions des utilisateurs E-Tachawor par rapport à l’actualité financière au Maroc. Le but est de créer un espace de débat entre les différents acteurs adhérents à la plateforme, à savoir : La coalition marocaine pour la redevabilité, les parlementaires, les journalistes, les étudiants chercheurs.    </p>
  </div>
</template>

<script setup>
  import {ref} from "vue";
  const isActive = ref(true);
  const hideOnboardingCard = () => {
    window.localStorage.setItem('isFirstLogin','false');
    isActive.value = false;
  };
</script>

<style lang="scss" scoped>
  .onboarding{
    @apply bg-white p-4 pb-6 rounded-md 
    flex flex-col items-center text-center
    relative mb-4;
    &__close{
      @apply absolute right-4 top-4 fill-[#000]
      cursor-pointer;
    }
    &__icon{
      @apply w-10 h-10 mb-3;
    }
    &__title{
      @apply text-lg font-semibold mb-2;
    }
    &__desc{
      @apply text-base max-w-[800px] text-gray-300;
    }
  }
</style>  